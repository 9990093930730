// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bv-js": () => import("./../../../src/pages/bv.js" /* webpackChunkName: "component---src-pages-bv-js" */),
  "component---src-pages-ff-js": () => import("./../../../src/pages/ff.js" /* webpackChunkName: "component---src-pages-ff-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ltt-js": () => import("./../../../src/pages/ltt.js" /* webpackChunkName: "component---src-pages-ltt-js" */),
  "component---src-pages-mqb-js": () => import("./../../../src/pages/mqb.js" /* webpackChunkName: "component---src-pages-mqb-js" */),
  "component---src-pages-sd-js": () => import("./../../../src/pages/sd.js" /* webpackChunkName: "component---src-pages-sd-js" */),
  "component---src-pages-sheesh-js": () => import("./../../../src/pages/sheesh.js" /* webpackChunkName: "component---src-pages-sheesh-js" */),
  "component---src-pages-tm-js": () => import("./../../../src/pages/tm.js" /* webpackChunkName: "component---src-pages-tm-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

